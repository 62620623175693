<template>
<!--  反馈详情-->
<div class="feedBackDetails">
  <div class="feedInfo">
    <van-cell  size="large">
    <template style="color: black" #title>
      <span >标题：</span>
      <span >{{ infoList.title }}</span>
    </template>
    <template #label>
      <span class="custom-title">{{ infoList.content }}</span>
      <div style="margin-top: 5%">{{infoList.createdTime}}</div>
    </template>
    </van-cell>
    <van-field
        v-model="infoList.replyContent"
        rows="2"
        size="large"
        autosize
        :readonly="true"
        label="反馈结果:"
        type="textarea"
    />
  </div>
</div>
</template>

<script>
export default {
  name: "feedBackDetails",
  data(){
    return{
      infoList: {},
    }
  },
  created() {
    this.infoList = JSON.parse(this.$route.query.str);
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .van-field__label{
  width: auto!important;
  color: black!important;
}
::v-deep .van-field__control{
  color: #969799!important;
}
</style>
